body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f3f7;
  font-family: 'Outfit', sans-serif;
  color: #373b46;
  font-size: 16px;
  font-weight: 400;
}

code {
  font-family: 'Outfit', sans-serif;
}
.headerBar .navbar {
  background:url('./assets/headerBg.jpg');
  position: relative;
  box-shadow: 0px 10px 3px -10px rgb(0, 0, 0, 0.1); 
  padding:18px 10px;
}

.headerBar .navbar .navbar-brand {
  width: 160px;
  padding: 0;
  position: absolute;

}
.headerBar .navbar .navbar-brand img {
  max-width: 100%;
  margin:0 auto;
}
.headerBar .navbar .nav-link {
  font-size: 17px;
  font-weight: 500;
  color: #373b46;
  padding: 0 20px;
  font-family: 'Outfit', sans-serif;

}
.whiteWrapper {
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  margin: 30px 0;
}

/* .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table {
  font-family: 'Outfit', sans-serif !important;
  color: #373b46 !important;
  font-size: 13px !important;
  font-weight: 600; 
} */

.blue-vista-wrapper .ant-table-content :where(.css-dev-only-do-not-override-1qhpsh8).ant-btn-primary {
 background: transparent !important;
 color: #005596 !important;
 box-shadow: none!important;
 border: 0 !important;
 padding: 0 !important;
 outline: 0 !important;
 font-size: 18px !important;
}
.blue-vista-wrapper .ant-table-content :where(.css-dev-only-do-not-override-1qhpsh8).ant-btn-primary:hover {
  background: transparent!important;
  color: #003d6b!important;
}
.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead >tr>th, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead >tr>td {
  background: #0d76c6!important;
}


.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-container table>thead>tr:first-child >*:first-child {
  border-radius: 8px  0 0 8px !important;
}
.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-container table>thead>tr:first-child >*:last-child {
  border-radius: 0 8px 8px 0 !important;
}

.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-cell, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead>tr>th, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-tbody>tr>th, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-tbody>tr>td, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable tfoot>tr>th, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable tfoot>tr>td {
  padding: 8px 15px !important;
}
.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead >tr>th, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead >tr>td 
{
  color: #ffffff  !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead >tr>th::before, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-thead >tr>td::before {
  display:none !important;
}
.blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-cell, .blue-vista-wrapper .ant-table-wrapper.grid-table.importpageTable .ant-table-tbody>tr>td {
  padding: 5px 15px !important;
}

.formWrapper {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin: 30px 0;
}

.formWrapper h4 {
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  color: #373b46;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.blue-vista-wrapper .ant-form .ant-form-item {
  margin: 0 0 10px 0;
}
.blue-vista-wrapper .ant-form .ant-form-item .ant-form-item-label {
  padding: 0 0 3px 0;
}
.blue-vista-wrapper .ant-form .ant-form-item .ant-form-item-label >label {
  color: #40434f;
  font-size: 14px;
  font-weight: 600;
}
.blue-vista-wrapper .ant-form .ant-form-item .ant-input {
  border: solid 1px #ebeff4;
  border-radius: 5px;
  padding: 6px 11px;
}
.blue-vista-wrapper .ant-form .ant-form-item .ant-picker {
  border: solid 1px #cfd5dc;
  border-radius: 5px;
  padding: 6px 11px;
}
.blue-vista-wrapper .ant-form .ant-form-item .ant-select-selector {
  border: solid 1px #cfd5dc;
  border-radius: 5px;
  padding: 8px 11px;
}
.blue-vista-wrapper .ant-form .ant-form-item .ant-select-single {
  height: 36px;
}


.blue-vista-wrapper .ant-form .ant-btn-primary {
  background: #005596;
  height: 36px;
  /* padding: 10px 20px; */
}
.blue-vista-wrapper .ant-form .ant-btn-primary:hover {
  background: #003d6b !important;
}
.formWrapper .ant-row .ant-row {
  margin: 0 20px 0 0;
}

.navbar-collapse {
  margin: 0 0 0 6%;
}
.report-btns {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 0 0;
}
.report-btns button {
  margin: 0 10px;
  padding: 5px 20px;
}

.login-screen {
  background: transparent url("./assets/loginBg.jpg") 50% 0 no-repeat;
   background-size: cover;
   height: 100%;
   width: 100%;
   left: 0;
   top: 0;
   position: fixed;
   font-family: 'Lato', sans-serif;
}
.loginBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}
.loginBox .login-logo {
  text-align: center;
  margin: 0 0 20px 0;
}
.loginBox .login-logo img {
  width:75%;
  margin: 0 auto;
}
.loginForm {
  background: rgba(0, 85, 150, 0.8);
  padding:20px 30px;
  border-radius: 10px;
}
.loginForm .ant-form-item label {
  color: #ffffff !important;
  font-family: 'Lato', sans-serif;
  font-weight: 400 !important;
  font-size: 15px !important;
}
.loginForm .ant-form-item label::before{
  display: none !important;
}
.blue-vista-wrapper .loginForm .ant-form-item {
  margin: 0 0 15px 0;
}
.blue-vista-wrapper .loginForm .ant-form-item .ant-input, .blue-vista-wrapper .loginForm .ant-form-item .ant-input-password {
  border-radius: 5px;
  border: 0;
  padding: 8px 10px;
  font-size: 14px;
}
.blue-vista-wrapper .loginForm .ant-form-item .ant-input-password {
  padding: 0 8px 0 0;
}
.blue-vista-wrapper .loginForm .ant-form .ant-btn-primary {
  background: #000000;
  border: 0;
  outline: 0;
  border-radius: 5px;
  height: 45px !important;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}
.blue-vista-wrapper .loginForm .ant-form .ant-btn-primary:hover {
  background: #002039 !important;
}
.blue-vista-wrapper .loginForm .ant-form .ant-form-item-explain-error {
  color: #ff4d4f;
  font-size: 13px;
  font-weight: 700;
}
.logout-btn {
  background:transparent;
  border-radius: 0;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  padding: 0 10px;
}
.logout-btn svg {
  font-size: 20px;
}
.logout-btn span {
  margin: 0 0 0 8px;
}
.logout-btn:hover, .logout-btn:focus {
  color: rgba(255, 255, 255, 0.5) !important;
  outline: 0;
  background: transparent;
}

.greport-btn {
  border-radius:6px;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  padding: 0 15px;
  margin: 0 10px 0 0;
  color: #373b46;
  outline: 0;
}
.greport-btn:focus {
  outline: 0;
}
.reportWrapper {
  margin:30px auto 0 auto !important; 
  border-top: solid 1px #c7c9cc;
  padding: 30px 0 0 0;
  width: 100%;
}
.blue-vista-wrapper .ant-form .view-btn {
  background: transparent;
  border:0;
  color: rgba(0, 85, 150, 0.8);
  outline: 0;
  box-shadow: none;
  padding: 0;
}
.blue-vista-wrapper .ant-form .view-btn:hover {
  background: transparent !important;
  color: rgba(0, 85, 150, 0.5);
}
.blue-vista-wrapper .ant-form .reportWrapper .reportBtns {
 display: flex;
 align-items: center;
 margin: 0 0 10px 0;
 justify-content: end;
}
.blue-vista-wrapper .ant-form .reportWrapper .reportBtns .ant-btn {
  background: transparent;
  outline: 0;
  font-size: 25px;
  padding: 5px;

  line-height: 100%;
  margin: 0 0 0 8px;
  text-align: center;
  border: solid 1px #cfd5dc;
  box-shadow: none;
}
.blue-vista-wrapper .ant-form .reportWrapper .reportBtns .ant-btn:focus {
  outline: 0;
  font-size: 25px;
}
.blue-vista-wrapper .ant-form .reportWrapper .reportBtns .ant-btn.mail-btn {
  color: #e99523;
}
.blue-vista-wrapper .ant-form .reportWrapper .reportBtns .ant-btn.download-btn {
  color: #80b3ff;
  font-size: 27px;
}
.blue-vista-wrapper .ant-form .reportWrapper .reportBtns .ant-btn.download-btn:focus {
  font-size: 27px;
}
.blue-vista-wrapper .navbar-nav {
  width: 100%;
  justify-content: center;
}
.blue-vista-wrapper .navbar-nav .navTitle {
  color: #ffffff;
  font-size: 25px;
  margin: 0 0 0 0;
}

.blue-vista-wrapper .search-field .ant-input-outlined {
  border: solid 1px #cfd5dc;
  border-right: 0;
    border-radius: 5px;
    padding: 6px 11px;
    border-top-right-radius: 0;
}
.blue-vista-wrapper .search-field .ant-btn {
  border: solid 1px #cfd5dc !important;
  border-left: 0 !important;
  padding: 6px 11px;
  height: 35px;
  margin: 0 0 0 -2px;
}
.defaultBtns {
  background: transparent;
  outline: 0;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: 600;
  line-height: 100%;
  margin: 0 0 0 8px;
  text-align: center;
  border: solid 1px #cfd5dc;
  box-shadow: none;
  color: #373b46;
  display: flex;
  align-items: center;
}
.defaultBtns svg {
  font-size: 15px;
  margin: 2px 5px 0 0;
}
.defaultBtns:focus {
  outline: 0;
}
.blue-vista-wrapper hr {
  border-color: #c7c9cc;
  margin: 20px 0 30px 0;
}
.headerBar h1 {
position: absolute;
left: 0;
right: 0;
margin: 0 auto;
text-align: center;
color: #ffffff;
font-size: 25px;
padding: 0;
width: 40%;
}

.grid-table .ant-table-content thead .ant-table-cell {
  padding: 8px 15px !important;
  background: #0d76c6!important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.grid-table .ant-table-content thead .ant-table-cell::before {
  display: none;
}
.grid-table .ant-table-content  tbody .ant-table-cell {
  padding: 5px 15px !important;
  font-family: 'Outfit', sans-serif !important;
  color: #373b46 !important;
  font-size: 13px !important;
  font-weight: 600;
}
.grid-table .ant-table-content .ant-btn-primary {
  background: transparent !important;
  color: #005596 !important;
  box-shadow: none!important;
  border: 0 !important;
  padding: 0 !important;
  outline: 0 !important;
  font-size: 18px !important
}
.grid-table .ant-table-content .ant-btn-primary:hover {
  background: transparent!important;
  color: #003d6b!important;
}

.btns-row {
  border-top: solid 1px #c7c9cc;
  height: 1px;
  margin: 20px 0 35px 0;
  padding: 15px 0 0 0;
  display: flex;
  justify-content: end;
}
.blue-vista-wrapper .ant-form .ant-form-item.password-field .ant-input {
  border: 0;
  padding: 4px 0;
}